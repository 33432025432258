import * as React from 'react';
import {IHostProps, ISantaProps} from '@wix/native-components-infra/dist/es/src/types/types';
import {IPropsInjectedByViewerScript} from '../types/app-types';

const PropsContext = React.createContext<IProvidedGlobalProps>(undefined);

export interface IProvidedGlobalProps {
  globals?: IPropsInjectedByViewerScript;
}

export function withGlobalPropsProvider(Component) {
  return (props: IPropsInjectedByViewerScript & ISantaProps & {style: IHostProps['style']}) => {
    return (
      <PropsContext.Provider value={{globals: {...props}}}>
        <Component {...props} />
      </PropsContext.Provider>
    );
  };
}

export function withGlobalProps(Component: any): React.FunctionComponent | any {
  return props => {
    return <PropsContext.Consumer>{globalProps => <Component {...globalProps} {...props} />}</PropsContext.Consumer>;
  };
}
